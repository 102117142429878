@import 'styles/utils';

.linkText.linkText {
  font-size: 14px;
  line-height: 20px;
}

.link {
  text-decoration: none;
  color: inherit;
  &:hover {
    @include color-opacity;
  }
}
